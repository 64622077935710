<template >
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0" :class="mode ? 'text__day2' : 'text__night2'"> {{ $t("message.model") }}</p>
          <el-button type="warning" @click="closeModal()"> {{ $t("message.close") }}</el-button>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" v-loading="loadStaff">
        <el-row :gutter="20">
          <el-col
            :span="8"
            v-for="(staff, index) in staff_list"
            :key="'staff-' + index"
           
          >
            <div class="timeline-items__right rounded-sm w-100 p-4"  @click="pushToProfile(staff)" :class="mode ? 'table__myday' : 'table__mynight'">
              <div class="info-user-left2" :class="mode ? '' : 'performance__itemnight'">
                <div class="img">
                  <img
                    :src="
                      staff.image
                        ? baseUrl + staff.image.path
                        : 'img/avatar.jpeg'
                    "
                    alt=""
                  />
                </div>
                <div class="name-info-user-left">
                  <div class="name_u">
                    {{ staff.name + " " + staff.last_name }}
                  </div>
                  <div class="position_u" v-if="staff.position" :class="mode ? 'text__day2' : 'text__night2'">
                    {{ staff.position.name }}
                  </div>
                   <div class="position_u" :class="mode ? 'text__day2' : 'text__night2'" >
                    {{ staff.id }}
                  </div>
                  <!-- <div class="salary">
                    <div><h4>Зарплата</h4></div>
                    <div>
                      <ul class="salary-list">
                        <li
                          v-for="(value, index) in staff.salary_amounts"
                          :key="'salary-' + index"
                        >
                          {{ value.toLocaleString() }} {{ index }}
                        </li>
                      </ul>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </el-col>
          <!-- <el-col :span="8">
            <div class="timeline-items__right rounded-sm w-100 p-4">
              <div class="info-user-left2">
                <div class="img">
                  <img
                    src="https://api.bmt.gohr.uz//uploads/files/images/stored/11_01_2022_17_06_37.jpg"
                    alt=""
                  />
                </div>
                <div class="name-info-user-left">
                  <div class="name_u">Diyora Fatullayeva</div>
                  <div class="position_u">
                    Мутахасис (Режалаштириш тўкув бўлими)
                  </div>
                  <div class="salary">
                    <div><h4>Зарплата</h4></div>
                    <div>
                      <ul class="salary-list">
                        <li>0 Узбекский сум</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="timeline-items__right rounded-sm w-100 p-4">
              <div class="info-user-left2">
                <div class="img">
                  <img
                    src="https://api.bmt.gohr.uz//uploads/files/images/stored/11_01_2022_17_06_37.jpg"
                    alt=""
                  />
                </div>
                <div class="name-info-user-left">
                  <div class="name_u">Diyora Fatullayeva</div>
                  <div class="position_u">
                    Мутахасис (Режалаштириш тўкув бўлими)
                  </div>
                  <div class="salary">
                    <div><h4>Зарплата</h4></div>
                    <div>
                      <ul class="salary-list">
                        <li>0 Узбекский сум</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="timeline-items__right rounded-sm w-100 p-4">
              <div class="info-user-left2">
                <div class="img">
                  <img
                    src="https://api.bmt.gohr.uz//uploads/files/images/stored/11_01_2022_17_06_37.jpg"
                    alt=""
                  />
                </div>
                <div class="name-info-user-left">
                  <div class="name_u">Diyora Fatullayeva</div>
                  <div class="position_u">
                    Мутахасис (Режалаштириш тўкув бўлими)
                  </div>
                  <div class="salary">
                    <div><h4>Зарплата</h4></div>
                    <div>
                      <ul class="salary-list">
                        <li>0 Узбекский сум</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="timeline-items__right rounded-sm w-100 p-4">
              <div class="info-user-left2">
                <div class="img">
                  <img
                    src="https://api.bmt.gohr.uz//uploads/files/images/stored/11_01_2022_17_06_37.jpg"
                    alt=""
                  />
                </div>
                <div class="name-info-user-left">
                  <div class="name_u">Diyora Fatullayeva</div>
                  <div class="position_u">
                    Мутахасис (Режалаштириш тўкув бўлими)
                  </div>
                  <div class="salary">
                    <div><h4>Зарплата</h4></div>
                    <div>
                      <ul class="salary-list">
                        <li>0 Узбекский сум</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="timeline-items__right rounded-sm w-100 p-4">
              <div class="info-user-left2">
                <div class="img">
                  <img
                    src="https://api.bmt.gohr.uz//uploads/files/images/stored/11_01_2022_17_06_37.jpg"
                    alt=""
                  />
                </div>
                <div class="name-info-user-left">
                  <div class="name_u">Diyora Fatullayeva</div>
                  <div class="position_u">
                    Мутахасис (Режалаштириш тўкув бўлими)
                  </div>
                  <div class="salary">
                    <div><h4>Зарплата</h4></div>
                    <div>
                      <ul class="salary-list">
                        <li>0 Узбекский сум</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="timeline-items__right rounded-sm w-100 p-4">
              <div class="info-user-left2">
                <div class="img">
                  <img
                    src="https://api.bmt.gohr.uz//uploads/files/images/stored/11_01_2022_17_06_37.jpg"
                    alt=""
                  />
                </div>
                <div class="name-info-user-left">
                  <div class="name_u">Diyora Fatullayeva</div>
                  <div class="position_u">
                    Мутахасис (Режалаштириш тўкув бўлими)
                  </div>
                  <div class="salary">
                    <div><h4>Зарплата</h4></div>
                    <div>
                      <ul class="salary-list">
                        <li>0 Узбекский сум</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col> -->
        </el-row>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
// import closeDialog from "../../../utils/mixins/dialog"
import drawer from "@/utils/mixins/drawer";

export default {
  name: "show-users",
  mixins: [drawer],
  components: {},
  data() {
    return {
      loadStaff: false,
      baseUrl: process.env.VUE_APP_URL.substring(
        0,
        process.env.VUE_APP_URL.length - 3
      ),
    };
  },

  computed: {
    ...mapGetters({
      staff_list: "attendanceDepartment/staff_list",
      mode: "MODE"
    }),
  },
  methods: {
    ...mapActions({
      getDepartmentStaffList: "attendanceDepartment/getDepartmentStaffList",
      getDepartmentStaffListComeIn:
        "attendanceDepartment/getDepartmentStaffListComeIn",
      getDepartmentStaffListExit:
        "attendanceDepartment/getDepartmentStaffListExit",
      getDepartmentStaffListNotComeIn:
        "attendanceDepartment/getDepartmentStaffListNotComeIn",
      getDepartmentStaffListIzn:
        "attendanceDepartment/getDepartmentStaffListIzn",
    }),
    opened(child) {
      if (!this.loadStaff && child.meth == "list") {
        this.loadStaff = true;
        this.getDepartmentStaffList(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }

      if (!this.loadStaff && child.meth == "come") {
        this.loadStaff = true;
        this.getDepartmentStaffListComeIn(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
      if (!this.loadStaff && child.meth == "come_exit") {
        this.loadStaff = true;
        this.getDepartmentStaffListExit(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
      if (!this.loadStaff && child.meth == "notcome") {
        this.loadStaff = true;
        this.getDepartmentStaffListNotComeIn(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
      if (!this.loadStaff && child.meth == "izn") {
        this.loadStaff = true;
        this.getDepartmentStaffListIzn(child)
          .then((res) => {
            this.loadStaff = false;
          })
          .catch((err) => {
            this.loadStaff = false;
          });
      }
    },
    pushToProfile(staff) {
      this.$router.push({
        name: "profile",
        params: { id: staff.id },
      });
    },
    closeModal() {
      this.$emit("closeUserModal", false);
    },
  },
};
</script>
<style lang="scss" scoped>
.timeline-items__right {
  margin-bottom: 20px;

  .info-user-left2 {
    display: flex;
    align-items: start;
    justify-content: flex-start;
    flex-wrap: wrap;

    .img {
      margin-right: 10px;
      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }
  .name_u {
    font-weight: bold;
    font-size: 15px;
  }
  .position_u {
    color: #8a8a8a;
    font-size: 12px;
    font-weight: 300;
    margin-top: 2px;
  }
  .salary {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 5px;
  }
}
</style>
